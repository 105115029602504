import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Grid, Card, CardContent, Typography, CardMedia, CircularProgress, Box, Button,
  Accordion, AccordionSummary, AccordionDetails, TextField, Tooltip
} from '@material-ui/core';
import { formatDistanceToNow, isWithinInterval } from 'date-fns';
import CloudIcon from '@material-ui/icons/Cloud';
import WhatshotIcon from '@material-ui/icons/Whatshot';
import OpacityIcon from '@material-ui/icons/Opacity';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InfoIcon from '@material-ui/icons/Info';
import CustomPagination from '../CustomPagination'; // Import custom pagination

const DeviceManager = () => {
  const [devices, setDevices] = useState([]);
  const [deviceImages, setDeviceImages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [trialInfo, setTrialInfo] = useState([]);
  const [sensorInfo, setSensorInfo] = useState([]);
  const [pumpValue, setPumpValue] = useState(0);

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const devicesPerPage = 25;

  const cardStyle = {
    border: '1px solid lightgray',
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
    borderBottomLeftRadius: '0px',
    borderBottomRightRadius: '0px',
    padding: '8px',
    margin: '0 4px',
    marginTop: '4px',
  };

  const accordionStyle = {
    border: '1px solid lightgray',
    borderTopLeftRadius: '0px',
    borderTopRightRadius: '0px',
    borderBottomLeftRadius: '8px',
    borderBottomRightRadius: '8px',
    margin: '0 4px',
  };

  const boxStyle = {
    border: '1px solid lightgray',
    borderRadius: '8px',
    padding: '8px',
    margin: '4px',
  };

  const buttonStyle = {
    backgroundColor: 'green',
    color: 'white',
    '&:hover': {
      backgroundColor: 'darkgreen',
    },
  };

  useEffect(() => {
    const fetchDevices = async () => {
      const domainData = localStorage.getItem('domain');
      let domain;
      if (domainData) {
        try {
          const parsedDomain = JSON.parse(domainData);
          domain = Array.isArray(parsedDomain) ? parsedDomain[0] : parsedDomain;

          if (typeof domain === 'string') {
            const response = await axios.get(`/devices/${domain}`);
            setDevices(response.data);
          }
        } catch (error) {
          console.error('Failed to fetch devices or parse domain:', error);
        }
      }
    };

    fetchDevices();
  }, []);

  useEffect(() => {
    const fetchDeviceImages = async () => {
      if (devices.length > 0) {
        setIsLoading(true);
        const payload = { deviceIDs: devices.map(device => device._id) };
        console.log("Payload for /getImages/latest-images/:", payload);
        const response = await axios.post('/getImages/latest-images/', payload);
        console.log("Response from /getImages/latest-images/:", response.data);
        setDeviceImages(response.data);
        setIsLoading(false);
      }
    };

    fetchDeviceImages();
  }, [devices]);

  useEffect(() => {
    const fetchTrialInfo = async () => {
      if (devices.length > 0) {
        try {
          const deviceIDs = devices.map(device => device._id);
          const response = await axios.post('/trials/current-trials', {
            deviceIDs: deviceIDs,
          });
          console.log("Response from /trials/current-trials:", response.data);

          const trials = response.data.map(trial => ({
            deviceId: trial.device_id,
            trialName: trial.trial_name,
            trialId: trial._id,
            experimentName: trial.experiment_name,
            start_date: trial.start_date,
          }));

          setTrialInfo(trials);
        } catch (error) {
          console.error('Failed to fetch trial info:', error);
        }
      }
    };

    fetchTrialInfo();
  }, [devices]);

  useEffect(() => {
    const fetchSensorData = async () => {
      if (trialInfo.length > 0) {
        console.log("Trial info:", trialInfo);
        const trialIds = trialInfo.map(trial => trial.trialId);
        console.log("Trial IDs for /sensor-data:", trialIds);
        try {
          const response = await axios.post('/environment/sensor-data', {
            trialIds: trialIds,
          });
          setSensorInfo(response.data);
          console.log("Response from /environment/sensor-data:", response.data);
        } catch (error) {
          console.error('Failed to fetch sensor data:', error);
        }
      }
    };

    fetchSensorData();
  }, [trialInfo]);

  const findImageForDevice = (deviceId) => {
    deviceImages.forEach(img => img.deviceID);
    return deviceImages.find((img) => img.deviceID === deviceId);
  };

  const handleFlashLEDClick = async (deviceID) => {
    try {
      // Existing route for legacy devices. This route will be deprecated in the future
      const response = await axios.get(`/devices/flash-LED/${deviceID}`);
      console.log(response.data);
  
      // New route for updated devices with firmware version 2.0 or higher
      const newResponse = await axios.post(`/devices/cloud-device-control/${deviceID}`, {
        controlCommand: "LED",
        controlValue: 1
      });
      console.log(newResponse.data);
    } catch (error) {
      console.error('There was a server error!', error);
    }
  };

  //  Adding a new route to handle the water pump action
  const handleAddWaterClick = async (deviceID) => {
    try {
      const newResponse = await axios.post(`/devices/cloud-device-control/${deviceID}`, {
        controlCommand: "PUMP",
        controlValue: pumpValue
      });
      console.log(newResponse.data);
    } catch (error) {
      console.error('There was a server error!', error);
    }
  };

  const formatLocalDateString = (dateString) => {
    const localDateString = dateString.replace('Z', '');
    return new Date(localDateString).toLocaleString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    });
  };

  const getStatusAndLastConnected = (lastHeartbeat) => {
    if (!lastHeartbeat) {
      return {
        status: 'Please update firmware',
        statusColor: 'red',
        lastConnected: '',
      };
    }

    const lastHeartbeatDate = new Date(lastHeartbeat);
    const sixMinutesAgo = new Date(Date.now() - 6 * 60 * 1000);
    const isOnline = isWithinInterval(lastHeartbeatDate, { start: sixMinutesAgo, end: new Date() });

    return {
      status: isOnline ? 'Online' : 'Offline',
      statusColor: isOnline ? 'green' : 'grey',
      lastConnected: `Latest Ping: ${formatDistanceToNow(lastHeartbeatDate, { addSuffix: true })}`,
    };
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const indexOfLastDevice = currentPage * devicesPerPage;
  const indexOfFirstDevice = indexOfLastDevice - devicesPerPage;
  const currentDevices = devices.slice(indexOfFirstDevice, indexOfLastDevice);

  return (
    <>
      <CustomPagination
        totalDevices={devices.length}
        devicesPerPage={devicesPerPage}
        currentPage={currentPage}
        onPageChange={handlePageChange}
      />
      <Grid container spacing={2}>
        {currentDevices.map((device) => {
          const imageInfo = findImageForDevice(device._id);
          const trial = trialInfo.find(t => t.deviceId === device._id) || {};
          const sensorData = sensorInfo.find(info => info.trialId === trial.trialId) || { data: [] };
          const { status, statusColor, lastConnected } = getStatusAndLastConnected(device.last_heartbeat);

          const temperature = sensorData.data.find(d => d.attribute === 'temperature');
          const humidity = sensorData.data.find(d => d.attribute === 'humidity');
          const co2 = sensorData.data.find(d => d.attribute === 'co2');

          return (
            <Grid item xs={12} sm={12} md={12} lg={6} xl={4} key={device._id}>
              <Card style={cardStyle}>
                <CardContent>
                  <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom="16px">
                    <Typography variant="h6" component="h2">
                      Device: {device.device_name}
                    </Typography>
                    <Typography variant="body2" style={{ marginLeft: 'auto', color: 'grey' }}>
                      Firmware: {device.firmware_version}
                    </Typography>
                  </Box>

                  <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography variant="body2" style={{ display: 'flex', alignItems: 'center' }}>
                      <span>Status:</span> <span style={{ marginLeft: '4px', color: statusColor }}>{status}</span>
                    </Typography>

                    {lastConnected && (
                      <Typography variant="body2" style={{ color: 'grey' }}>
                        {lastConnected}
                      </Typography>
                    )}
                  </Box>

                  <Box style={boxStyle}>
                    <Typography variant="body2">
                      <Box component="span" fontWeight="bold">Experiment:</Box> {trial.experimentName || 'N/A'}
                    </Typography>
                    <Typography variant="body2">
                      <Box component="span" fontWeight="bold">Trial:</Box> {trial.trialName || 'N/A'}
                    </Typography>
                    <Typography variant="body2">
                      <Box component="span" fontWeight="bold">Trial Age:</Box> {trial.start_date ? `${formatDistanceToNow(new Date(trial.start_date), { addSuffix: false })}` : 'N/A'}
                    </Typography>
                  </Box>

                  <Box display="flex" flexDirection="column" justifyContent="space-between">
                    <Box flexGrow={1} style={boxStyle}>
                      <Typography variant="subtitle1">Latest Image Received</Typography>
                      {isLoading ? (
                        <CircularProgress />
                      ) : imageInfo && imageInfo.imageURL ? (
                        <>
                          <CardMedia
                            component="img"
                            alt={`Image of ${device.device_name}`}
                            style={{
                              height: '140px',
                              width: 'auto',
                              objectFit: 'cover',
                              marginLeft: 'auto',
                              marginRight: 'auto',
                              display: 'block'
                            }}
                            image={imageInfo.imageURL}
                          />
                          <Typography variant="body2" color="textSecondary" component="p">
                            {imageInfo.timestamp ? new Date(imageInfo.timestamp).toLocaleString() : "No Timestamp Found"}
                          </Typography>
                        </>
                      ) : (
                        <Typography variant="body2" component="p">
                          No Image Found
                        </Typography>
                      )}
                    </Box>

                    <Box style={{ ...boxStyle, display: 'flex', flexDirection: 'column', gap: '8px' }}>
                      <Typography variant="subtitle1" style={{ marginBottom: '8px' }}>Latest Sensor Readings</Typography>
                      <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Box display="flex" alignItems="center">
                          <WhatshotIcon color="secondary" />
                          <Typography variant="body2">
                            Temp: {temperature ? `${temperature.value} ${temperature.unit}` : 'No data found for this trial'}
                          </Typography>
                        </Box>
                        <Typography variant="body2" color="textSecondary">
                          {temperature ? formatLocalDateString(temperature.observation_date) : ''}
                        </Typography>
                      </Box>
                      <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Box display="flex" alignItems="center">
                          <OpacityIcon color="primary" />
                          <Typography variant="body2">
                            Humidity: {humidity ? `${humidity.value} ${humidity.unit}` : 'No data found for this trial'}
                          </Typography>
                        </Box>
                        <Typography variant="body2" color="textSecondary">
                          {humidity ? formatLocalDateString(humidity.observation_date) : ''}
                        </Typography>
                      </Box>
                      <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Box display="flex" alignItems="center">
                          <CloudIcon color="tertiary" />
                          <Typography variant="body2">
                            CO2: {co2 ? `${co2.value} ${co2.unit}` : 'No data found for this trial'}
                          </Typography>
                        </Box>
                        <Typography variant="body2" color="textSecondary">
                          {co2 ? formatLocalDateString(co2.observation_date) : ''}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </CardContent>
              </Card>

              <Accordion style={accordionStyle}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>Device Control</Typography>
                </AccordionSummary>
                <AccordionDetails style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                  <Box style={boxStyle}>
                    <Typography variant="h6" style={{ textAlign: 'left' }}>LED Control</Typography>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                      <Typography variant="body2">
                        Flash the LED
                        <Tooltip title="This will flash the LED on the device. This can be useful for verifying connectivity, and locating one specific device on a rack.">
                          <InfoIcon style={{ marginLeft: '4px', fontSize: 'small' }} />
                        </Tooltip>
                      </Typography>
                      <Button variant="contained" style={buttonStyle} onClick={() => handleFlashLEDClick(device._id)}>
                        Flash LED
                      </Button>
                    </Box>
                  </Box>

                  <Box style={boxStyle}>
                    <Typography variant="h6" style={{ textAlign: 'left' }}>Pump Control</Typography>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                      <Box display="flex" alignItems="center">
                        <Typography variant="body2">
                          Add water (measured in mL)
                          <Tooltip title="Available for firmware versions 2.0 and above. This will add the specified amount of water to the irrigation tray. The pump speed is 80mL/minute, so large values will take some time to finish. The amount pumped will be added to the trial data for consistency.">
                            <InfoIcon style={{ marginLeft: '4px', fontSize: 'small' }} />
                          </Tooltip>
                        </Typography>
                      </Box>
                      <Box display="flex" alignItems="center">
                        <TextField
                          type="number"
                          variant="outlined"
                          size="small"
                          value={pumpValue}
                          onChange={(e) => setPumpValue(Number(e.target.value))}
                          style={{ marginRight: '8px', width: '100px' }}
                        />
                        <Button variant="contained" style={buttonStyle} onClick={() => handleAddWaterClick(device._id)}>
                          Add Water
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </AccordionDetails>
              </Accordion>
            </Grid>
          );
        })}
      </Grid>
      <CustomPagination
        totalDevices={devices.length}
        devicesPerPage={devicesPerPage}
        currentPage={currentPage}
        onPageChange={handlePageChange}
      />
    </>
  );
};

export default DeviceManager;
