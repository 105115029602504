import React from 'react';
import { Box, Button } from '@material-ui/core';

const CustomPagination = ({ totalDevices, devicesPerPage, currentPage, onPageChange }) => {
  const totalPages = Math.ceil(totalDevices / devicesPerPage);

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
    }
  };

  return (
    <Box display="flex" justifyContent="center" alignItems="center" mb={2}>
      <Button onClick={handlePreviousPage} disabled={currentPage === 1}>Previous</Button>
      <Box mx={2}>Page {currentPage} of {totalPages}</Box>
      <Button onClick={handleNextPage} disabled={currentPage === totalPages}>Next</Button>
    </Box>
  );
};

export default CustomPagination;
