import React, { useState, useEffect } from 'react';
import { Button, Typography, Table, TableBody, TableCell, TableHead, TableRow, TableContainer, Paper, Box, makeStyles, withStyles, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import axios from 'axios';
import verifyDomain from '../../functions/verifyDomain';
import { useSelectedExperiment } from '../../contexts/ExperimentContext';

// ---------------------------------------------------------- Custom Styling ---------------------------------------------------------- //

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  tableContainer: {
    width: '70%',
    maxHeight: 655, // Set a maximum height
    overflowY: 'auto', // Enable vertical scrolling
    boxShadow: theme.shadows[5],
  },
  managementBox: {
    width: '30%',
    height: '60%',
    maxHeight: 655, // Set a maximum height
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
    marginLeft: theme.spacing(2),
    boxShadow: theme.shadows[5],
  },
  innerBox: {
    margin: theme.spacing(2, 0),
    padding: theme.spacing(2),
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    maxWidth: 400,
  },
  greenButton: {
    backgroundColor: 'green',
    '&:hover': {
      backgroundColor: 'darkgreen',
    },
    color: 'white',
    margin: theme.spacing(1), // Adjusted for alignment and spacing
  },
  table: {
    minWidth: 650,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    width: '100%', // Ensures the select takes the full width of its container
  },
}));

const TrialManager = () => {
  const classes = useStyles();

  //Function kicks user out if they aren't logged in with a valid domain
  verifyDomain();

  // Add state to store the trials for the table and dropdown
  const [trials, setTrials] = useState([]);
  const [refreshTrials, setRefreshTrials] = useState(false); // This state triggers a re-fetch of trials

  // Add state to store the devices and the selected device
  const [devices, setDevices] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState('');

  // Add state to store the recipes and the selected recipe
  const [recipes, setRecipes] = useState([]);
  const [selectedRecipeCreate, setSelectedRecipeCreate] = useState('');
  const [selectedRecipeUpdate, setSelectedRecipeUpdate] = useState('');

  // State to store the selected trial for updating
  const [selectedTrialForUpdate, setSelectedTrialForUpdate] = useState('');

  // Get the selected experiment from context
  const { selectedExperiment: experiment } = useSelectedExperiment();

  // ---------------------------------------------------------- Event Handlers for Creating and Updating Trials ---------------------------------------------------------- //

  // onClick handler for creating a new trial
  // This function should be called when the "Create Trial" button is clicked
  const handleCreateTrial = async () => {
    const trialName = prompt("Enter a Trial Name");
  
    if (!trialName || trialName.trim() === '') {
      alert("Trial name is required.");
      return;
    }
    
    if (!selectedDevice || !selectedRecipeCreate) {
      alert("Please select both a device and a recipe.");
      return;
    }
  
    const deviceDetails = devices.find(device => device._id === selectedDevice);
    const recipeDetails = recipes.find(recipe => recipe._id === selectedRecipeCreate);
  
    if (!deviceDetails || !recipeDetails) {
      alert("Selected device or recipe details not found.");
      return;
    }
  
    try {
      const createBlankTrialResponse = await axios.post('/sendRecipe/createBlankTrial');
      const blankTrialId = createBlankTrialResponse.data._id;
      console.log('Created blank trial:', createBlankTrialResponse.data);
      
      // Correctly obtaining the current timestamp in milliseconds
      const trialPayloadTime = new Date().toISOString();

      const trialCreatePayload = {
        trialPayload: { // A common parent key to group them together
          trial_details: {
            trial_name: trialName,
            model_name: "Trial",
            active: true,
            device_id: deviceDetails._id,
            device_name: deviceDetails.device_name,
            recipe_state: [
              {
                recipe_id: recipeDetails._id,
                recipe_name: recipeDetails.recipe_name,
                execution_date: trialPayloadTime,
              },
            ],
            start_date: trialPayloadTime,
            experiment_id: experiment?._id,
            experiment_name: experiment?.experiment_name,
          },
          type: 'Create',
          formatted_trial: {
            _id: blankTrialId,
            model_name: "Trial",
            recipe_name: recipeDetails.recipe_name,
            recipe_variable: recipeDetails.recipe_variable,
            phases: recipeDetails.phases,
            recipe_id: recipeDetails._id,
            trial_name: trialName,
            device_id: deviceDetails._id,
            device_name: deviceDetails.device_name,
            start_date: Math.floor(Date.now() / 1000),
          },
        }
      };
      

      // Assuming the endpoint URL and the Axios setup are correct
      // Replace '/api/trials/create' with your actual endpoint
      const response = await axios.post('/sendRecipe/createTrial', trialCreatePayload);
      console.log('did it work?:', response.data);
      waitAndRefreshTrials(); // Wait and then refresh the trials list after 10 seconds
    } catch (error) {
      console.error('Failed to create trial:', error);
      alert('Failed to create trial.');
    }
  };

  // onClick handler for updating an existing trial
  // This function should be called when the "Update Trial" button is clicked
  const handleUpdateTrial = async () => {
    // Prompt for confirmation or additional input if needed
    if (!selectedTrialForUpdate || !selectedRecipeUpdate) {
      alert("Please select a trial and a recipe to update.");
      return;
    }
  
    // Find the details for the selected trial and recipe
    const trialToUpdate = trials.find(trial => trial._id === selectedTrialForUpdate);
    const newRecipeDetails = recipes.find(recipe => recipe._id === selectedRecipeUpdate);
  
    if (!trialToUpdate || !newRecipeDetails) {
      alert("Selected trial or recipe details not found.");
      return;
    }
  
    // Prepare the updated recipe_state array and formatted_trial object
    const updatedRecipeState = [
      ...trialToUpdate.recipe_state,
      {
        recipe_id: newRecipeDetails._id,
        recipe_name: newRecipeDetails.recipe_name,
        execution_date: new Date().toISOString(), // Current timestamp for when this update was made
      }
    ];

    const trialUpdatePayload = {
      trialPayload: { // A common parent key to group them together
        trial_details: {
          trial_name: trialToUpdate.trial_name,
          model_name: "Trial",
          active: true,
          device_id: trialToUpdate.device_id,
          device_name: trialToUpdate.device_name,
          recipe_state: updatedRecipeState,
          start_date: trialToUpdate.start_date,
          experiment_id: trialToUpdate.experiment_id,
          experiment_name: trialToUpdate.experiment_name,
        },
        type: 'Update',
        formatted_trial: {
          _id: trialToUpdate._id,
          model_name: "Trial",
          recipe_name: newRecipeDetails.recipe_name,
          recipe_variable: newRecipeDetails.recipe_variable,
          phases: newRecipeDetails.phases,
          recipe_id: newRecipeDetails._id,
          trial_name: trialToUpdate.trial_name,
          device_id: trialToUpdate.device_id,
          device_name: trialToUpdate.device_name,
          start_date: Math.floor(new Date(trialToUpdate.start_date).getTime() / 1000),
        },
      }
    };
  
    try {
      const response = await axios.post(`/sendRecipe/createTrial`, trialUpdatePayload);
      console.log('Trial updated successfully:', response.data);
      waitAndRefreshTrials(); // Wait and then refresh the trials list after 10 seconds
    } catch (error) {
      console.error('Failed to update trial:', error);
      alert('Failed to update trial.');
    }
  };

  // ---------------------------------------------------------- Minor Functions ---------------------------------------------------------- //

  const handleDeviceChange = (event) => {
    setSelectedDevice(event.target.value);
  };  

  const handleRecipeChangeCreate = (event) => {
    setSelectedRecipeCreate(event.target.value);
  };

  const handleRecipeChangeUpdate = (event) => {
    setSelectedRecipeUpdate(event.target.value);
  };
  const handleTrialChangeForUpdate = (event) => {
    setSelectedTrialForUpdate(event.target.value);
  };  

  // Function to wait before refreshing the trials list
  const waitAndRefreshTrials = () => {
    setTimeout(() => {
      setRefreshTrials(!refreshTrials);
      console.log('Trials Refreshed'); // Toggle the state to trigger re-fetch
    }, 20000); // Wait for 20 seconds
  };

  // ---------------------------------------------------------- Hooks for Fetching Data ---------------------------------------------------------- //

  useEffect(() => {
    const fetchTrials = async () => {
      const experimentId = experiment?._id;
  
      try {
        const response = await axios.get(`/trials/exp/${experimentId}`);
        // Sort trials alphabetically by trial_name
        const sortedTrials = response.data.sort((a, b) => a.trial_name.localeCompare(b.trial_name));
        setTrials(sortedTrials);
      } catch (error) {
        console.error('Failed to fetch trials:', error);
      }
    };
  
    fetchTrials();
  }, [refreshTrials, experiment]);

  useEffect(() => {
    const fetchDevices = async () => {
      // Retrieve the domain from localStorage and parse the JSON string
      const domainData = localStorage.getItem('domain');
      let domain;
      if (domainData) {
        try {
          // Parse the stored JSON string to an array and get the first element
          const parsedDomain = JSON.parse(domainData);
          domain = Array.isArray(parsedDomain) ? parsedDomain[0] : parsedDomain;
          
          // Make sure domain is a string and not undefined
          if (typeof domain === 'string') {
            // Update the URL to match your backend endpoint that expects a domain
            const response = await axios.get(`/devices/${domain}`);
            setDevices(response.data);
          }
        } catch (error) {
          console.error('Failed to fetch devices or parse domain:', error);
        }
      }
    };
  
    fetchDevices();
  }, []);
  
  useEffect(() => {
    const fetchRecipes = async () => {
      if (experiment) {
        const experimentId = experiment?._id;
        
        try {
          // Adjust the URL according to your actual API endpoint for fetching recipes
          const response = await axios.get(`/templateRecipes/byExperiment/${experimentId}`);
          // Sort recipes alphabetically by recipe_name
          const sortedRecipes = response.data.sort((a, b) => {
            return a.recipe_name.localeCompare(b.recipe_name);
          });
          setRecipes(sortedRecipes);
        } catch (error) {
          console.error('Failed to fetch recipes:', error);
        }
      }
    };
    // Call fetchRecipes when the component mounts or when the experiment data changes
    fetchRecipes();
  }, [experiment]);
  
  console.log('Rendering trials with state:', trials);
  return (
    <>
      <div className={classes.root}>
        <TableContainer component={Paper} className={classes.tableContainer}>
          <Table stickyHeader className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Trial Name</StyledTableCell>
                <StyledTableCell align="left">Device</StyledTableCell>
                <StyledTableCell align="left">Start Date</StyledTableCell>
                <StyledTableCell align="left">Last Updated</StyledTableCell>
                <StyledTableCell align="left">Experiment</StyledTableCell>
                <StyledTableCell align="left">Current Recipe</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {trials.map((trial) => (
                <TableRow key={trial._id}>
                  <TableCell component="th" scope="row">{trial.trial_name}</TableCell>
                  <TableCell align="left">{trial.device_name}</TableCell>
                  <TableCell align="left">
                    {new Date(trial.start_date).toLocaleString(undefined, {
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                      hour: '2-digit',
                      minute: '2-digit',
                    })}
                  </TableCell>
                  <TableCell align="left">
                    {
                      trial.recipe_state && trial.recipe_state.length > 0
                      ? new Date(trial.recipe_state[trial.recipe_state.length - 1].execution_date).toLocaleString(undefined, {
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                      })
                      : new Date(trial.start_date).toLocaleString(undefined, {
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                      })
                    }
                  </TableCell>
                  <TableCell align="left">{trial.experiment_name}</TableCell>
                  <TableCell align="left">
                    {
                      // Check if recipe_state exists and has length before accessing
                      trial.recipe_state && trial.recipe_state.length > 0 
                      ? trial.recipe_state[trial.recipe_state.length - 1].recipe_name 
                      : trial.recipe_name // Fallback to recipe_name if recipe_state is empty
                    }
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Box className={classes.managementBox}>
          <Typography variant="h5" style={{ padding: '2px', textAlign: 'center' }}>Trial Management</Typography>
          <Box className={classes.innerBox}>
            <Typography variant="h6">New Trial Creation</Typography>
            <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="device-select-label">Select a Device</InputLabel>
                <Select
                labelId="device-select-label"
                id="device-select"
                value={selectedDevice}
                onChange={handleDeviceChange}
                label="Select a Device"
                >
                {devices.map((device) => (
                    <MenuItem key={device._id} value={device._id}>
                    {device.device_name} {/* Assuming the device object has a name property */}
                    </MenuItem>
                ))}
                </Select>
            </FormControl>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="recipe-select-label">Select a Recipe</InputLabel>
              <Select
                labelId="recipe-select-label"
                id="recipe-select"
                value={selectedRecipeCreate}
                onChange={handleRecipeChangeCreate}
                label="Select a Recipe"
              >
                {recipes.map((recipe) => (
                  <MenuItem key={recipe._id} value={recipe._id}>
                    {recipe.recipe_name} {/* Assuming each recipe has a recipe_name */}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button variant="contained" className={classes.greenButton} onClick={handleCreateTrial}>
                Create Trial
            </Button>
          </Box>


          <Box className={classes.innerBox}>
            <Typography variant="h6">Update Existing Trial</Typography>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="trial-select-label-update">Select a Trial</InputLabel>
              <Select
                labelId="trial-select-label-update"
                id="trial-select-update"
                value={selectedTrialForUpdate}
                onChange={handleTrialChangeForUpdate}
                label="Select a Trial"
              >
                {trials.map((trial) => (
                  <MenuItem key={trial._id} value={trial._id}>
                    {trial.trial_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="recipe-select-label-update">Select a Recipe</InputLabel>
              <Select
                labelId="recipe-select-label-update"
                id="recipe-select-update"
                value={selectedRecipeUpdate}
                onChange={handleRecipeChangeUpdate} // Reusing the recipe change handler
                label="Select a Recipe"
              >
                {recipes.map((recipe) => (
                  <MenuItem key={recipe._id} value={recipe._id}>
                    {recipe.recipe_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button variant="contained" className={classes.greenButton} onClick={handleUpdateTrial}>
                Update Trial
            </Button>
          </Box>
        </Box>
      </div>
    </>
  );
};


export default TrialManager;
